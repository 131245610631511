<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">助成申請 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                v-model="search.year"
                @change="regetList(search.year)"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                @change="setSortBy"
                :options="apptypeList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="searchMiddle inlineBlock searchSpace">
            <div>文理区分</div>
            <div>
              <b-form-select
                :options="bunriOption"
                v-model="search.bunri">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                  <!-- <option :value="null">区分なし</option> -->
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請者名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                v-model="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="changeSearch">
          <b-form-checkbox
            v-model="search.isChenged"
            value="1"
            switches
          >
            計画変更がある申請のみを表示する
          </b-form-checkbox>
        </div>
      </div>
      <div class="mb-5 flex flexCenter contentsWidth mx-auto">
        <b-button
          class="btn btn-lg bold"
          to="/cms/top">トップに戻る</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="selectedAppIdList.length < 1 || search.year === 'all'"
          @click="control(status['RECEIPT'])"
          >一括受領</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportCsv()"
          >CSV出力</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportPdf"
          @click="exportPdf()"
          >PDF出力</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg mr-2 bold ml-2"
          :disabled="this.selectedAppIdList.length < 1"
          to="/cms/applications/mail">メール送信</b-button>
      </div>
      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1 select-pop-over-trigger">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 select-pop-over" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 select-pop-over" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 select-pop-over" @click="allSelect">全選択</p>
                <p class="mb-0 select-pop-over" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedAppIdList($event)"
                :checked="selectedAppIdList"
                :value="row.item.id"
                switches
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(dates)="row">
            {{row.item.last_acceptance_date}}
          </template>
          <template v-slot:cell(changeHistory)="row">
            <span v-show="row.item.changeCount">〇</span>
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/cms/applications/edit/'+row.item.id">
              詳細
            </b-link>
          </template>
        </b-table>
        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="selectedAppIdList.length < 1 || search.year === 'all'"
        @click="control(status['RECEIPT'])"
        >一括受領</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canExportCsv"
        @click="exportCsv()"
        >CSV出力</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canExportPdf"
        @click="exportPdf()"
        >PDF出力</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-2"
        :disabled="this.selectedAppIdList.length < 1"
        to="/cms/applications/mail">メール送信</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        @click="goHead()"
        >上へ</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
import download from '@/modules/download';

export default {
  name: 'CmsApplicationsList',
  data() {
    return {
      status: CONST_STATUS.APP_STATUS,
      dbAppList: [],
      header: [
        {
          key: 'checkbox',
          label: '',
          sortable: false,
          class: '',
        },
        {
          key: 'kana',
          label: '申請者名',
          sortable: true,
          class: '',
        },
        {
          key: 'institution',
          label: '所属/申請団体',
          sortable: true,
          class: '',
        },
        {
          key: 'apptype',
          label: '助成プログラム',
          sortable: true,
          class: '',
        },
        {
          key: 'code',
          label: '申請番号',
          sortable: true,
          class: '',
        },
        {
          key: 'biko',
          label: '備考',
          sortable: false,
          class: 'biko',
        },
        {
          key: 'statusText',
          label: 'ステータス',
          sortable: true,
          class: '',
        },
        {
          key: 'changeHistory',
          label: '計画変更',
          sortable: false,
          class: '',
        },
        {
          key: 'btn',
          label: '',
          sortable: false,
          class: '',
        },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      seriesList: [],
      yearList: [],
      isInit: true,
      oldPage: 1,
      sortDesc: false,
      sortBy: 'preGroup',
      pdfErr: null,
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const apptypeSort = search.series === null || lineData.apptype === search.series || (search.series === '社会的文化的諸活動助成' && lineData.apptype === '社会的・文化的諸活動助成');
      const yearBunri = search.bunri === '' || lineData.bunri === search.bunri;
      const codeSort = search.code === '' || (lineData.code && lineData.code.includes(search.code));
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      const isChenged = search.isChenged === null || lineData.changeCount >= Number(search.isChenged);
      return nameSort && apptypeSort && codeSort && statusSort && yearBunri && isChenged;
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsApplicationSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsApplicationSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsApplicationSearch/setCurrentPage', 1);
      }
      this.$store.commit('cmsApplicationSearch/initSelectedAppid');
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsApplicationSearch/setFilterdIdList', filteredItems);
    },
    async initFetch() {
      const param = { year: this.search.year };
      const promiseFuncs = [api.send('/api/cms/applications/list', param)];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      this.dbAppList = responses[0].data.list;
      this.seriesList = responses[0].data.seriesList;
      this.yearList = responses[0].data.yearList;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.$store.commit('cmsApplicationSearch/setFilterdIdList', this.dbAppList);
      this.setSortBy();
    },
    setSeries(apptypeId) {
      const target = this.seriesList.find((series) => {
        return series.apptypeList.find((apptype) => {
          return apptype.id === Number(apptypeId);
        });
      });
      if (target) {
        const seriesData = this.apptypeList.find(element => element.id === target.id);
        this.$set(this.search, 'series', seriesData.text);
      }
    },
    async regetList(year) {
      this.$store.dispatch('page/onWaiting');
      if (year !== null && year !== '' && year !== 'all') {
        if (year < 2025) {
          if (this.search.series === '学術調査研究助成') {
            this.$set(this.search, 'series', '調査研究助成');
          }
          if (this.search.series === 'デジタルイノベーション社会実装助成') {
            this.$set(this.search, 'series', '目的型調査研究助成');
          }
        } else {
          if (this.search.series === '目的型諸活動助成') {
            this.$set(this.search, 'series', null);
          }
          if (this.search.series === '調査研究助成') {
            this.$set(this.search, 'series', '学術調査研究助成');
          }
          if (this.search.series === '目的型調査研究助成') {
            this.$set(this.search, 'series', 'デジタルイノベーション社会実装助成');
          }
        }
      }
      await this.initFetch(year);
      this.$store.dispatch('page/offWaiting');
    },
    async control(status) {
      const count = this.selectedAppIdList.length;
      let msg = `選択中の${count}件の申請のステータスを受領に変更します。`;
      msg += '\nステータスが変更されると、申請者にメールの送信と通知の登録が行われます。\n受領してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        status,
        selectedAppIdList: this.selectedAppIdList,
      };
      const response = await api.send('/api/cms/applications/control', params)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        return;
      }
      await this.alert('選択した申請を受領しました。', false);
      await this.initFetch(this.search.year);
      this.$store.dispatch('page/offWaiting');
    },
    allSelect() {
      this.$store.commit('cmsApplicationSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsApplicationSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsApplicationSearch/addSelectedAppid', appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsApplicationSearch/removeSelectedAppid', appId);
      });
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const seriesData = this.apptypeList.find(element => element.text === this.search.series);
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: seriesData.id,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/export/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${this.search.series}.csv`;
      download.csvUtf(response.data, fileName);
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const seriesData = this.apptypeList.find(element => element.text === this.search.series);
      this.pdfErr = null;
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: seriesData.id,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/export/pdf', params, requireBlob)
        .catch(async (err) => {
          const error = JSON.parse(await err.response.data.text());
          if (error && error.msg) {
            this.pdfErr = error.msg;
          }
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        // eslint-disable-next-line
        if (this.pdfErr) {
          await this.alert(this.pdfErr);
        } else {
          await this.alert('ファイルのダウンロードに失敗しました。');
        }
        return;
      }
      const ymd = moment().format('YYYMMDD');
      const fileName = `${ymd}_${this.search.series}.pdf`;
      download.blob(response.data, fileName);
    },
    updateSelectedAppIdList(list) {
      this.$store.commit('cmsApplicationSearch/setSelectedAppid', list);
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsApplicationSearch/setFilter', param);
    },
    setCurrentPage(page) {
      this.$store.commit('cmsApplicationSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsApplicationSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsApplicationSearch/setTotalRows', value);
    },
    goHead() {
      const Ease = {
        easeInOut: (t) => { return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; },
      };
      const duration = 500;
      // 現在のスクロール位置を取得（クロスブラウザに対応）
      const currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
      // スタート時点の時間を取得
      const startTime = performance.now();
      // スクロール先の要素の位置を取得
      const targetPosition = 0;
      // アニメーションのループを定義
      const loop = (nowTime) => {
        // スタートからの経過時間を取得
        const time = nowTime - startTime;
        // duration を1とした場合の経過時間を計算
        const normalizedTime = time / duration;
        // duration に経過時間が達していない場合はアニメーションを実行
        if (normalizedTime < 1) {
          // 経過時間とイージングに応じてスクロール位置を変更
          window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * Ease.easeInOut(normalizedTime)));
          // アニメーションを継続
          requestAnimationFrame(loop);
        // duration に経過時間が達したら、アニメーションを終了
        } else {
          window.scrollTo(0, targetPosition);
        }
      };
      // アニメーションをスタート
      requestAnimationFrame(loop);
    },
    setSortBy() {
      const self = this;
      const target = [];
      const noneResultAnnouncemented = [];
      const changeApps = ['調査研究助成', '目的型調査研究助成', '目的型諸活動助成'];
      const maxYear = changeApps.includes(this.search.series) ? 2024 : Math.max(...this.yearList);
      if (Object.keys(this.seriesList).length > 0) {
        Object.keys(this.seriesList).some((key) => {
          const series = self.seriesList[key];
          const setData = series.apptypeList.find((apptype) => {
            if (this.search.year === 'all') {
              return apptype.year === Number(maxYear);
            }
            return apptype.year === Number(self.search.year);
          });
          if (setData) {
            if (self.search.series && series.id === Number(self.search.series)) {
              target.push({ id: series.id, value: setData.result_announcement_datetime, name: series.name });
              return true;
            }
            if (!self.search.series) {
              target.push({ id: series.id, value: setData.result_announcement_datetime, name: series.name });
            }
          }
          return false;
        });
        Object.keys(target).forEach((key) => {
          const apptype = target[key];
          if (apptype.value === null) {
            const typeData = self.appList.find((data) => {
              return data.apptype === apptype.name;
            });
            if (typeof typeData !== 'undefined' && typeData) {
              noneResultAnnouncemented.push(apptype);
            }
            return;
          }
          const now = moment();
          const end = moment(apptype.value);
          if (now.isBefore(end)) {
            noneResultAnnouncemented.push(apptype);
          }
        });
      }
      this.sortBy = noneResultAnnouncemented.length !== 0 ? 'preGroup' : 'last_send_date';
    },
    async handleRouteChange() {
      if (Object.keys(this.$route.query).length === 0) {
        this.$store.dispatch('page/onLoading');
        this.oldPage = this.currentPage;
        this.$store.commit('cmsApplicationSearch/initSelectedAppid');
        const { year, apptypeId } = this.$route.params;
        if (year) {
          this.setFilter('year', year);
        }
        await this.initFetch();
        this.setSeries(apptypeId);
        this.isInit = false;
        this.$store.dispatch('page/offLoading');
      }
    },
  },
  computed: {
    statusList() {
      const option = CONST_STATUS.CMS_APP_STATUS_OPTION_4_LIST;
      const sortNum = CONST_STATUS.CMS_APP_STATUS_OPTION_4_SORT_LIST;
      const showOption = [];
      Object.keys(option).forEach(key => showOption.push({ id: sortNum[key], value: key, text: option[key] }));
      showOption.sort((a, b) => a.id - b.id);
      return showOption;
    },
    bunriOption() {
      return ['文', '理'];
    },
    appList() {
      const appList = this.dbAppList.map((application) => {
        let bunri = '';
        if (application.bunri === 1 || application.bunri === 2) {
          bunri = this.bunriOption[application.bunri - 1];
        }
        let name = '';
        let kana = '';
        if (application.ans_name && application.ans_kana) {
          application.ans_name.forEach((data) => {
            name += `${data.field_answer_text} `;
          });
          application.ans_kana.forEach((data) => {
            kana += `${data.field_answer_text} `;
          });
        }
        let institution = '';
        if ((application.apptype.name === '国際会議開催助成' || application.apptype.name === '社会的文化的諸活動助成') && application.ans_apply_org.length > 0) {
          const applyOrg = application.ans_apply_org[0];
          institution = applyOrg.field_answer_text;
        } else {
          institution = application.university_name;
          if (!institution) {
            // eslint-disable-next-line
            institution = application.institution;
          }
        }

        let biko = '';
        if (bunri !== '') {
          biko += `文理区分：${bunri}\n`;
        }
        if (application.apptype.name === '国際会議開催助成' && application.ans_representative_name.length > 0) {
          biko += '代表者：';
          application.ans_representative_name.forEach((data) => {
            biko += `${data.field_answer_text} `;
          });
          biko += '\n';
        }
        if ((application.apptype.name === '調査研究助成' || application.apptype.name === '学術調査研究助成' || application.apptype.name === '目的型調査研究助成' || application.apptype.name === 'デジタルイノベーション社会実装助成') && application.ans_num_collaborators.length > 0) {
          const ansNumCollaborator = application.ans_num_collaborators[0];
          biko += '共同研究者数：';
          biko += `${ansNumCollaborator.field_answer_text}人\n`;
        }
        if (application.apptype.name === '外国人留学生助成' && application.ans_nation.length > 0) {
          const ansNation = application.ans_nation[0];
          biko += `国籍：${ansNation.field_answer_text}\n`;
        }
        return {
          id: application.id,
          apptypeId: application.application_type_id,
          apptype: application.apptype.name,
          name,
          kana,
          bunri,
          biko,
          year: application.apptype.year,
          code: application.code,
          status: application.status,
          statusText: CONST_STATUS.APP_STATUS_TEXTS[application.status],
          seriesId: application.series.series_id,
          last_send_date: application.last_send_date,
          last_acceptance_date: application.last_acceptance_date,
          institution,
          preGroup: application.pre_group,
          changeCount: application.change_count,
        };
      });
      return appList;
    },
    search() {
      return this.$store.state.cmsApplicationSearch.search;
    },
    currentPage() {
      return this.$store.state.cmsApplicationSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsApplicationSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsApplicationSearch.totalRows;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    adjustedApptypeData() {
      let optionDbData = this.seriesList;
      if (this.search.year === 'all') {
        optionDbData = [];
        this.seriesList.forEach((series) => {
          optionDbData.push(series);
          if (series.id === 2) {
            optionDbData.push({ id: series.id, name: '調査研究助成' });
          }
          if (series.id === 8) {
            optionDbData.push({ id: series.id, name: '目的型調査研究助成' });
          }
        });
      }

      return optionDbData;
    },
    apptypeList() {
      const filterSeriesList = [];
      this.adjustedApptypeData.forEach((data) => {
        if ((this.search.year !== null && (this.search.year === 'all' || this.search.year < 2025)) || data.id !== 9) {
          filterSeriesList.push(data);
        }
      });
      const appTypeList = filterSeriesList.map((series) => {
        if (this.search.year !== null) {
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 2) {
            return { value: '調査研究助成', text: '調査研究助成', id: 2 };
          }
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 8) {
            return { value: '目的型調査研究助成', text: '目的型調査研究助成', id: 8 };
          }
        }
        return { value: series.name, text: series.name, id: series.id };
      });
      return appTypeList;
    },
    selectedAppIdList() {
      return this.$store.state.cmsApplicationSearch.selectedAppIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsApplicationSearch.filterdIdList;
    },
    canExportCsv() {
      const { series } = this.search;
      const number = this.selectedAppIdList.length > 0;
      return number && series;
    },
    canExportPdf() {
      const { year, series } = this.search;
      const number = this.selectedAppIdList.length > 0;
      return number && series && year !== 'all';
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    this.$store.commit('cmsApplicationSearch/initSelectedAppid');
    const { year, apptypeId } = this.$route.params;
    if (year) {
      this.setFilter('year', year);
    }
    await this.initFetch();
    this.setSeries(apptypeId);
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
  watch: {
    // クエリパラメータの変更を監視
    '$route.query': 'handleRouteChange',
  },
};
</script>

<style>
  .cmsAppTable thead th:nth-of-type(1) {
    width: 70px !important;
  }
  .cmsAppTable thead th:nth-of-type(2) {
    min-width: 125px !important;
  }
  .cmsAppTable thead th:nth-of-type(3) {
    min-width: 155px !important;
  }
  .cmsAppTable thead th:nth-of-type(5) {
    width: 132px !important;
  }
  .cmsAppTable thead th:nth-of-type(6) {
    width: 180px !important;
  }
  .cmsAppTable thead th:nth-of-type(7) {
    width: 100px !important;
  }
  .cmsAppTable thead th:nth-of-type(8) {
    width: 65px !important;
  }
  .cmsAppTable thead th:nth-of-type(9) {
    width: 65px !important;
  }
  .cmsAppTable .biko {
    white-space: break-spaces;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    /*margin-bottom: 30px;*/
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 155px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  .err-wrap>p{
    color: #dc3545;
  }
  .changeSearch{
    background: #ddd;
    padding: 10px;
    padding-top: 0;
    margin-bottom: 30px;
  }
</style>
